"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require('slick-carousel');
function InitJuomat() {
    var timeout;
    window.addFullWidth = function () {
        if (!$(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").addClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").addClass("has-bg-image");
        }
    };
    window.removeFullWidth = function () {
        if ($(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").removeClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").removeClass("has-bg-image");
        }
    };
    window.isMobileDevice = function () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // true for mobile device
            return true;
        }
        else {
            // false for not mobile device
            return false;
        }
    };
    /* ProductPage, _ImportedProductItem.cshtml */
    window.handleImageError = function (elem) {
        console.log('handleImageError');
        console.log($(elem));
        var fallbackIndex = $(elem).data("fallback");
        var fallbacks = $(elem).data("fallbacks").split(",");
        if (fallbackIndex > fallbacks.length - 1)
            return;
        $(elem).attr("src", fallbacks[fallbackIndex]);
        var fallBackAdd = eval($(elem).data("fallback"));
        fallBackAdd++;
        $(elem).data("fallback", fallBackAdd);
    };
    $('#toggle-product-info').click(function () {
        console.log('clicked');
        $('#product-info').toggleClass('closed');
        $('#toggle-product-info').toggleClass('open');
    });
    /* ProductSearchPage */
    /* events */
    $('.facet-header').click(function (e) {
        if ($(this).parent('.facet-dropdown').hasClass('open')) {
            $(this).parent('.facet-dropdown').removeClass('open');
        }
        else {
            $('.facet-dropdown').removeClass('open');
            $(this).parent('.facet-dropdown').addClass('open');
        }
        e.stopPropagation();
    });
    $('body.ProductSearchPage').click(function (e) {
        if ($(e.target).parents(".facets-bg").length) {
            if (!$(e.target).parents('.facet-sub-items').length) {
                $('.facet-dropdown').removeClass('open');
            }
        }
    });
    $('.facets-checkbox').click(function () {
        $('.o-container.search-results').addClass('loading');
        $('form#form0').submit();
    });
    $('#searchword').on('keydown', function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            console.log('keydown');
            $('form#form0').submit();
        }, 1000);
    });
    $('#toggle-mobile-filters').click(function () {
        $(this).toggleClass('open');
        $('#facets-wrapper').toggleClass('open');
    });
    window.resetForm = function (e) {
        var resetForm = document.getElementById('form0');
        resetForm.reset();
        document.querySelectorAll('.facets-checkbox').forEach(function (checkbox) {
            checkbox.removeAttribute("checked");
        });
        updateUrlSegment(null, false, true);
        $('.o-container.search-results').addClass('loading');
        $('#form0').submit();
    };
    window.scrollToResult = function () {
        var topMargin = 540;
        if ($(window).innerWidth() >= 768) {
            topMargin = 660;
        }
        $("html, body").animate({
            scrollTop: $('.o-container.search-results').position().top + topMargin
        });
    };
    window.hideMobileKeyboardOnReturn = function (element) {
        console.log('here?', element);
        element.addEventListener('keyup', function (keyboardEvent) {
            var key = keyboardEvent.code || keyboardEvent.keyCode;
            if (key === 'Enter' || key === 13) {
                element.blur();
                setTimeout(function () {
                    /* Hide mobile filters */
                    $('#toggle-mobile-filters').removeClass('open');
                    $('#facets-wrapper').removeClass('open');
                    window.scrollToResult();
                }, 500);
            }
        });
    };
    window.startFetch = function () {
        $('.o-container.search-results').addClass('loading');
    };
    if ($('body.ProductSearchPage').length) {
        $(document).ready(function () {
            var formElement = document.getElementById('searchword');
            window.hideMobileKeyboardOnReturn(formElement);
            $('#form0').submit();
        });
    }
    function updateUrlSegment(filterValue, isSelected, reset) {
        if (window.ProductSearchSettings.baseUrl) {
            var path = window.location.pathname;
            var segments = path.split('/').filter(Boolean); // Split path and remove any empty segments
            var filterPathSegments = path.endsWith('/') ?
                path.replace(window.ProductSearchSettings.baseUrl, "").split('/').filter(Boolean) :
                (path + '/').replace(window.ProductSearchSettings.baseUrl, "").split('/').filter(Boolean);
            var allSelectedCheckboxes = Array.from(document.querySelectorAll('.facets-checkbox')).filter(function (checkbox) { return checkbox.checked; });
            if ((filterPathSegments.length >= 1 &&
                filterValue &&
                filterValue != filterPathSegments[0]) ||
                allSelectedCheckboxes.length > 1 ||
                reset) {
                window.history.pushState({}, '', window.ProductSearchSettings.baseUrl);
            }
            else {
                if (isSelected) {
                    // Add the filter segment if it doesn't exist
                    if (!segments.includes(filterValue)) {
                        segments.push(filterValue);
                    }
                }
                else {
                    //check if we are left with 1 selected filter => refresh url
                    if (allSelectedCheckboxes.length == 1) {
                        segments.push(allSelectedCheckboxes[0].getAttribute('data-href'));
                    }
                    // Remove the filter segment
                    segments = segments.filter(function (segment) { return segment !== filterValue; });
                }
                // Reconstruct the URL
                var updatedPath = '/' + segments.join('/');
                window.history.pushState({}, '', updatedPath);
            }
            // Update the meta title dynamically - just for show
            updateMetaTitle(allSelectedCheckboxes);
        }
    }
    function updateMetaTitle(allSelectedCheckboxes) {
        var metaTitle = window.ProductSearchSettings.defaultMetaTitle;
        if (allSelectedCheckboxes.length == 1) {
            metaTitle = allSelectedCheckboxes.map(function (checkbox) { return checkbox.getAttribute('data-meta-title'); })[0];
        }
        var metaElement = document.querySelector("meta[name=\"title\"]");
        var ogMetaElement = document.querySelector("meta[name=\"og:title\"]");
        if (metaElement) {
            metaElement.setAttribute("content", metaTitle);
        }
        if (ogMetaElement) {
            ogMetaElement.setAttribute("content", metaTitle);
        }
        document.title = metaTitle;
    }
    document.addEventListener('DOMContentLoaded', function init() {
        if (typeof window !== 'undefined' && window.ProductSearchSettings) {
            document.querySelectorAll('.facets-checkbox').forEach(function (checkbox) {
                checkbox.addEventListener('change', function (event) {
                    updateUrlSegment(event.target.getAttribute('data-href'), this.checked, false);
                });
            });
        }
    });
}
exports.InitJuomat = InitJuomat;
