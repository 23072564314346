"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var ProductBox_1 = require("./Elements/ProductBox");
var Store_1 = require("./Store");
var ContentArea_1 = require("./Elements/ContentArea");
var Hero_1 = require("./Elements/Hero");
var ProductList = /** @class */ (function (_super) {
    __extends(ProductList, _super);
    function ProductList(props) {
        var _this = _super.call(this, props) || this;
        Store_1.default.loadProducts();
        return _this;
        //store.updateNavigationColor(store.containerId);
    }
    ProductList.prototype.componentDidMount = function () {
        document.title = Store_1.default.currentPageName + " | Hartwall";
    };
    ProductList.prototype.render = function () {
        var ingress = Store_1.default.SelectedBrand.ingress;
        return (React.createElement(React.Fragment, null,
            React.createElement("article", { id: "main-content" },
                React.createElement(Hero_1.default, { backgroundImageUrl: Store_1.default.SelectedBrand.imageUrl, heading: Store_1.default.SelectedBrand.name, dimFilter: Store_1.default.SelectedBrand.dimFilter }),
                React.createElement("div", { className: "o-ingress row u-flex-justify--ce u-mt-6 u-mb-6" },
                    React.createElement("div", { className: "col-xs-10 col-sm-9 col-md-9 col-lg-7 u-tc s-themecolor-2" }, ingress)),
                React.createElement("div", { className: "o-container" },
                    React.createElement("div", { className: "o-product-listing-wrapper" },
                        Store_1.default.loading &&
                            React.createElement("div", { className: "ui active inverted dimmer" },
                                React.createElement("div", { className: "ui text loader" }, Store_1.default.Translations.loadingText)),
                        React.createElement("div", { className: "row u-pb-2 m-pr-1" }, Store_1.default.Products && Store_1.default.Products.map(function (product) {
                            return (React.createElement(ProductBox_1.default, { key: product.id, product: product, columnSize_medium: 4 }));
                        }))))),
            React.createElement("section", { id: "related-articles" },
                React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedBrand.id, language: Store_1.default.currentLanguage, area: "BottomContentArea", backupArea: "ProductContentArea", backupContent: Store_1.default.containerId }))));
    };
    ProductList = __decorate([
        mobx_react_1.observer
    ], ProductList);
    return ProductList;
}(React.Component));
exports.default = ProductList;
