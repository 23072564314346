"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('./../scss/sites/juomat/juomat.scss');
var index_1 = require("./../js/index");
var juomat_1 = require("./Sites/juomat");
// Initialize scripts that are used in all websites
index_1.Init();
// Import site specific scripts
juomat_1.InitJuomat();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
index_1.InitReact();
