"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var Store_1 = require("./Store");
var ContentArea_1 = require("./Elements/ContentArea");
var Hero_1 = require("./Elements/Hero");
var mobx_1 = require("mobx");
var CategoryList = /** @class */ (function (_super) {
    __extends(CategoryList, _super);
    function CategoryList(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            disableSlider: window.innerWidth > 768
        };
        return _this;
    }
    CategoryList.prototype.componentDidMount = function () {
        document.title = Store_1.default.currentPageName + " | Hartwall";
        //store.updateNavigationColor(store.containerId);
        window.addEventListener('resize', this.updateWidth.bind(this));
    };
    CategoryList.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.updateWidth);
    };
    CategoryList.prototype.updateWidth = function () {
        if (this.state.disableSlider === false && window.innerWidth > 768) {
            this.setState({ disableSlider: true });
        }
    };
    CategoryList.prototype.onShowAllClick = function () {
        this.setState({ disableSlider: true });
    };
    CategoryList.prototype.render = function () {
        var hasCategories = Store_1.default.Categories !== null && Store_1.default.Categories.length > 0;
        var canRenderSlider = this.state.disableSlider === false && hasCategories === true && Store_1.default.Categories.length > 3;
        var productTypesText = Store_1.default.Translations.productTypesText;
        var sliderSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3.5,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2
                    }
                }
            ]
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(Hero_1.default, { backgroundImageUrl: Store_1.default.BackgroundImageUrl, heading: Store_1.default.Translations.headerText, dimFilter: Store_1.default.DimFilter, ingressText: Store_1.default.Translations.ingressText, link: Store_1.default.readMoreLink, linkText: Store_1.default.Translations.readMoreLinkText }),
            React.createElement(ContentArea_1.default, { content: Store_1.default.containerId, language: Store_1.default.currentLanguage, area: "BottomContentArea" })));
    };
    __decorate([
        mobx_1.action
    ], CategoryList.prototype, "onShowAllClick", null);
    CategoryList = __decorate([
        mobx_react_1.observer
    ], CategoryList);
    return CategoryList;
}(React.Component));
exports.default = CategoryList;
